<style lang="scss">
  @import './App.scss';
</style>

<template>
  <div v-if="show_modal" class="community-profile-modal-container">
    <div class="modal-backdrop" v-on:click="toggleModal(false)"></div>
    <div v-if="memberInfo === null" class="modal">
      <div class="modal-header">
        <h1>Accedi al tuo profilo MyPlay</h1>
        <a v-on:click="toggleModal(false)" class="close-modal-btn">
          <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0595 3.05994L18.9395 0.939941L10.9995 8.87994L3.05945 0.939941L0.939453 3.05994L8.87945 10.9999L0.939453 18.9399L3.05945 21.0599L10.9995 13.1199L18.9395 21.0599L21.0595 18.9399L13.1195 10.9999L21.0595 3.05994Z" fill="#DEDEDE"/>
          </svg>
        </a>
      </div>
      <div class="modal-body">
        <div>
            <label for="email">Email</label>
            <input type="text" name="email" v-model="login.email.text" />
            <div class="validation-error" v-if="login.email.error !== ''">{{login.email.error}}</div>
        </div>
        <div>
            <label>Password</label>
            <input type="password" v-model="login.password.text" />
            <div class="validation-error" v-if="login.password.error !== ''">{{login.password.error}}</div>
        </div>
        <div>
            <div v-if="login.error !== ''" class="validation-error mt-1 py-half">{{login.error}}</div>
            <div><a href="#" v-on:click="checkForm()" class="btn">Continua</a></div>
            <div class="lost-password"><a href="https://myplay.rtl.it/hai-dimenticato-la-password/" target="_blank" title="Password dimenticata?">Password dimenticata?</a></div>
            <div class="lost-password"><a href="https://myplay.rtl.it/account-non-attivo/" target="_blank" title="Password dimenticata?">Account non attivo?</a></div>
        </div>
        <div class="modal-separator">
            <div><strong>oppure</strong></div>
        </div>
        <div>
            <div class="mb-1"><a href="#" v-on:click="doSocialLogin('google')" class="btn has-icon btn-brands-google"><i class="icon-google"></i> Continua con Google</a></div>
            <div class="mb-1"><a href="#" v-on:click="doSocialLogin('facebook')" class="btn has-icon btn-brands-facebook"><i class="icon-facebook"></i> Continua con Facebook</a></div>
            <div class="mb-1 pb-half"><a href="#" v-on:click="doSocialLogin('apple')" class="btn has-icon btn-brands-apple"><i class="icon-apple"></i> Continua con Apple</a></div>
            <div v-if="socialLogin.error !== ''" class="validation-error mt-1 py-half">
                {{socialLogin.error}} 
                <a v-if="socialLogin.token !== ''" v-bind:href="'https://myplay.rtl.it/registrati/?slToken=' + socialLogin.token" target="_blank" class="text-underline">Crea un nuovo account</a>
            </div>
        </div>
        <div class="lost-password">Non hai ancora un account? <a href="https://myplay.rtl.it/registrati/" target="_blank" title="Registrati">Registrati</a></div>
    </div>
    </div>
    <div v-if="memberInfo !== null" class="modal">
      <div class="modal-header">
        <h1>Il tuo profilo MyPlay</h1>
        <a v-on:click="toggleModal(false)" class="close-modal-btn">
          <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0595 3.05994L18.9395 0.939941L10.9995 8.87994L3.05945 0.939941L0.939453 3.05994L8.87945 10.9999L0.939453 18.9399L3.05945 21.0599L10.9995 13.1199L18.9395 21.0599L21.0595 18.9399L13.1195 10.9999L21.0595 3.05994Z" fill="#DEDEDE"/>
          </svg>
        </a>
      </div>
      <div class="modal-body">
        <div class="profile-picture">
          <img :src="profilePictureUrl" :alt="memberName" />
        </div>
        <div class="profile-name">{{memberName}}</div>
        <div class="my-2">
          <a href="https://myplay.rtl.it/" title="Gestisci il tuo account" target="_blank" class="btn has-icon"><i class="icon-settings"></i> Gestisci account</a>
        </div>
        <div class="mb-1">
          <a href="#" v-on:click="$emit('doLogout')" class="btn btn-logout">Disconnetti</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommunityProfileModal',
  data: () => ({
    memberInfo: null,
    login : {
      email: {
        text: '',
        error: ''
      },
      password: {
        text: '',
        error: ''
      },
      error : '',
    },
    socialLogin: {
      error: '',
      token: ''
    },
    show_modal : false,
  }),
  computed: {
    profilePictureUrl(){
      if (this.memberInfo === null){
          return 'https://cloud.rtl.it/Member/600/default.jpg';
      }
      
      if (this.memberInfo.images !== null && Object.prototype.hasOwnProperty.call(this.memberInfo.images, '600')){
          return this.memberInfo.images['600'];
      }

      return 'https://cloud.rtl.it/Member/600/default.jpg';
    },
    memberName() {
      if (this.memberInfo === null){
          return 'Utente sconosciuto';
      }

      return `${this.memberInfo.name} ${this.memberInfo.familyName}`;
    }
  },
  methods: {
    resetErrors() {
      this.login.email.error = '';
      this.login.password.error = '';
      this.login.error = '';
      this.socialLogin.error = '';
    },
    checkForm() {
      // check email
      this.resetErrors();
      
      if (this.login.email.text === ''){
        this.login.email.error = 'Questo campo è obbligatorio';
        return;
      }

      if (/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b\s*$/i.test(this.login.email.text) === false){
        this.login.email.error = 'Non è stato inserito un indirizzo e-mail valido';
        return;
      }
      
      if (this.login.password.text === ''){
        this.login.password.error = 'Questo campo è obbligatorio';
        return;
      }

      this.$emit('doPasswordLogin', { email : this.login.email.text, password: this.login.password.text });
    },
    doSocialLogin (platform) {
      this.resetErrors();
      this.$emit('doSocialLogin', platform);
    },
    toggleModal(newState = null) {
      if (newState === null){
        newState = !this.show_modal;
      }
      this.show_modal = newState;
      this.$emit(newState ? 'modalShown' : 'modalHidden');
    }
  }
}
</script>